import React from 'react';
import yellowBar from '../Images/cta button.png';
import Rectangle from '../Images/Rectangle.png';
import sample1 from '../Images/Artboard.png';
import orangeTrapezium from '../Images/rectangle-copy.png';
import orangeDiamond from '../Images/Rectangle Copy.png';
import redDiamond from '../Images/Rectangle Copy 2.png';
import greenDiamond from '../Images/Rectangle Copy 3.png';
import purpleShape from '../Images/Rectangle.png';

/* import * as mainScript from '../scripts'; */
/* import $ from 'jquery'; */
import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons';
/* import jQuery from 'jquery'; */

function openNav(e) {
    console.log("where?",e.target.className)
    if(e.target.className === 'nav-icon'){
        window.scrollTo({ top: 500, behavior: 'smooth'});
        e.target.classList.add("closebtn");
        e.target.innerHTML ="&times;"//&#9776
        if(document.getElementById("myNav"))document.getElementById("myNav").style.width = "100%";
    } else {
        e.target.classList.remove("closebtn");
        e.target.innerHTML ="&#9776;"//&#9776
        if(document.getElementById("myNav"))document.getElementById("myNav").style.width = "0%";
        document.getElementById('projects').scrollIntoView();
    }
  }


export default class renderHomepage extends React.Component{
    navClick(){
        var navIcon = document.getElementsByClassName("nav-icon");
        navIcon[0].classList.remove("closebtn");
        navIcon[0].innerHTML="&#9776;";//&#9776
        if(document.getElementById("myNav"))document.getElementById("myNav").style.width = "0%";
    }

    render(){
        return (
            <div style={{width: "100%"}}>
                <div id="myNav" className="overlay">
            {/*  <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a>  */}
                <div className="overlay-content">
                <a className="nav-link" href="#about" onClick={this.navClick.bind(this)}>About</a>
                <a className="nav-link" href="#projects" onClick={this.navClick.bind(this)}>Projects</a>
                <a className="nav-link" href="#signup" onClick={this.navClick.bind(this)}>Contact</a>
                </div>
            </div>
        {/* <!-- Navigation--> */}
            <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
                <div className="container-fluid">
                    <a className="navbar-brand js-scroll-trigger" href="#home">KNKT</a>
                    <span className="nav-icon" style={{fontSize: "30px", cursor: "pointer"}} onClick={openNav}>&#9776;</span>
                </div>
            </nav>
            {/* <Parallax ref={ref => (this.parallax = ref)} pages={5}> */}
            
            <header className="masthead" id="home">
                <div className="h-100 pull-center">
                        <div className="row w-100">
                            <div className="col-lg-6 text-right"> <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1></div>
                            <div className="col-lg-6">
                                <div className="text-center h-100 project">
                                    <div className="d-flex h-100">
                                        <div className="project-text w-100 my-auto text-center text-lg-left">
                                            <p className="mb-0 text-white-50 info-font">  Every Fan.<br></br>
                                                Every Performance.<br></br>
                                                Every Data Point.<br></br>
                                                Every Music.<br></br></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6"></div>
                            <div className="col-lg-6 no-padding"><img src={yellowBar}
                                srcSet="img/cta-button@2x.png 2x,
                                        img/cta-button@3x.png 3x"
                                className="cta-button"/></div>
                        </div>
                </div>
            </header>
        {/*   <!-- About--> */}
       {/*  <ParallaxLayer offset={0.2} speed={0.8} > */}
            <section className="masthead text-center" id="about">
            <div className="h-100 pull-center">
                        <div className="row w-100">
                        <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={orangeTrapezium}
                                    className="orange-trapezium"/></div>
                            <div className="col-lg-6 text-right"><h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1></div>
                            <div className="col-lg-6">
                                <div className="text-center h-100 project">
                                    <div className="d-flex h-100">
                                        <div className="project-text w-100 my-auto text-center text-lg-left">
                                            <p className="mb-0 text-white-50 info-font">  With Data<br></br>
                                                Every Performance.<br></br>
                                                Every Data Point.<br></br>
                                                Every Music.<br></br></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
           {/*  </ParallaxLayer> */}
         {/*    <ParallaxLayer offset={0.8} speed={0.8} >    */}
        {/*    <!-- Projects--> */}
            <section className="masthead text-center" id="projects">
            <div className="h-100 pull-center">
                        <div className="row w-100">
                            <div className="col-lg-6 text-right"><h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1></div>
                            <div className="col-lg-6 three-shapes-container pull-center">
                             
                                    <img src={orangeDiamond} className="orange-trapezium"/>
                                    <img src={greenDiamond} className="orange-trapezium"/>
                                    <img src={redDiamond}className="orange-trapezium"/>
                                </div>
                        </div>
                </div>
            </section>
          {/*   </ParallaxLayer> */}
            {/* <!-- Signup--> */}
          {/*   <ParallaxLayer offset={1.3} speed={0.8} > */}
            <section className="masthead text-center" id="signup">
            <div className="h-100 pull-center">
                        <div className="row w-100">
                        <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={purpleShape}
                                    className="orange-trapezium"/></div>
                            <div className="col-lg-6 text-right"><h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1></div>
                            <div className="col-lg-6">
                                <div className="text-center h-100 project">
                                    <div className="d-flex h-100">
                                        <div className="project-text w-100 my-auto text-center text-lg-left">
                                            <p className="mb-0 text-white-50 info-font">  With Data<br></br>
                                                Every Performance.<br></br>
                                                Every Data Point.<br></br>
                                                Every Music.<br></br></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
         {/*    </ParallaxLayer> */}
         {/*    <ParallaxLayer offset={1.6} speed={0.8} > */}
        {/*  <!-- Contact--> */}
            <section className="masthead text-center">
            <div className="h-100 pull-center">
                        <div className="row w-100">
                        <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={orangeTrapezium}
                                    className="orange-trapezium"/></div>
                            <div className="col-lg-6 text-right"><h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1></div>
                            <div className="col-lg-6">
                                <div className="text-center h-100 project">
                                    <div className="d-flex h-100">
                                        <div className="project-text w-100 my-auto text-center text-lg-left">
                                            <p className="mb-0 text-white-50 info-font">  With Data<br></br>
                                                Every Performance.<br></br>
                                                Every Data Point.<br></br>
                                                Every Music.<br></br></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
            {/* </ParallaxLayer> */}
          {/*   </Parallax> */}
            <footer className="footer bg-black small text-center text-white-50"><div class="container">Copyright © Your Website 2020</div></footer>
            </div>
        );
    }
}

//export default renderHomepage;