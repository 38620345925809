import React from 'react'
import ReactDOM from 'react-dom'
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import yellowBar from '../Images/cta button.png';
import Rectangle from '../Images/Rectangle.png';
import sample1 from '../Images/Artboard.png';
import orangeTrapezium from '../Images/rectangle-copy.png';
import orangeDiamond from '../Images/Rectangle Copy.png';
import redDiamond from '../Images/Rectangle Copy 2.png';
import greenDiamond from '../Images/Rectangle Copy 3.png';
import purpleShape from '../Images/Rectangle.png';
import purpleBlob from '../Images/page1_bg.png';
import audioCassette from '../assets/img/Audio Cassette.png';
import cartridge from '../assets/img/Cartridge.png';
import paSticker from '../assets/img/Sticker.png';
import sdCard from '../assets/img/SD Card.png';
import vinyl from '../assets/img/Vinil.png';
import videoTape from '../assets/img/Videotape.png';
import tape from '../assets/img/Tape.png';

// Little helpers ...
const url = (name, wrap = false) => `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`
const localurl = (name, wrap = false) => `${wrap ? 'url(' : ''}../asset/img/${name}.png${wrap ? ')' : ''}`
const Pink = ({ children }) => <span style={{ color: '#FF6AC1' }}>{children}</span>
const Yellow = ({ children }) => <span style={{ color: '#EFF59B' }}>{children}</span>
const Lightblue = ({ children }) => <span style={{ color: '#9AEDFE' }}>{children}</span>
const Green = ({ children }) => <span style={{ color: '#57EE89' }}>{children}</span>
const Blue = ({ children }) => <span style={{ color: '#57C7FF' }}>{children}</span>
const Gray = ({ children }) => <span style={{ color: '#909090' }}>{children}</span>

function openNav(e) {
  console.log("where?", e.target.className)
  if (e.target.className === 'nav-icon') {
    e.target.classList.add("closebtn");
    e.target.innerHTML = "&times;"//&#9776
    if (document.getElementById("myNav")) document.getElementById("myNav").style.width = "100%";
  } else {
    e.target.classList.remove("closebtn");
    e.target.innerHTML = "&#9776;"//&#9776
    if (document.getElementById("myNav")) document.getElementById("myNav").style.width = "0%";
    //document.getElementById('projects').scrollIntoView();
    window.scrollTo(0,3000);
  }
}

export default class ParallaxEx extends React.Component {

  constructor(props) {
    super(props);
    this.getCurrentPage = this.getCurrentPage.bind(this);
    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    //window.removeEventListener('scroll', (e)=>e.preventDefault(), false);
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    console.log('isMobile', isMobile);
    console.log(this.parallax);
    (isMobile) ? this.handleSwipe() : this.handleScroll();
    this.handleWindowResize();   
  }

  getCurrentPage() {
    const page = this.parallax.current / this.parallax.space;
    let currentPage = page % 1 > 0.9 ? Math.round(page) : Math.floor(page);
    return currentPage;
  }
  
  handleOnClick(){
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(!isMobile){
      let currentPage = this.getCurrentPage();
      console.log('isMobile', isMobile, currentPage);
      (currentPage !== 7) ? this.parallax.scrollTo(currentPage+1) : this.parallax.scrollTo(0);
      console.log(document.querySelector('#app1'));
      document.querySelector('#app1').scrollTo(0,500)
    }
  }

  handleWindowResize(){
    let rThis = this;
    window.onresize = function (event) {
      let currentPage = rThis.getCurrentPage();
      console.log('currentpage', currentPage);
      rThis.parallax.scrollTo(currentPage)
    };
  }

  handleScroll(){
    let rThis = this;
    var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "wheel" : "wheel";
    setTimeout(function(){
       
    
    window.addEventListener(mousewheelevt, function (e) {
      e.preventDefault();
      
      let wDelta = e.deltaY > 0 ? 'down' : 'up';
      let currentPage = rThis.getCurrentPage();
      if (wDelta === 'down') {
        setTimeout(function(){
          rThis.parallax.scrollTo(currentPage + 1);
        },500)
        
      } else {
        setTimeout(function(){
          rThis.parallax.scrollTo(currentPage - 1);
        },500)
      }
      console.log(wDelta, e.deltaY, currentPage);
    });
  }, 1000);
  }

  handleSwipe(){
      let touchstartX = 0;
      let touchstartY = 0;
      let touchendX = 0;
      let touchendY = 0;
      let rThis = this;
      //const gestureZone = document.getElementsByClassName('App');
      //if(!gestureZone) return;
      let currentPage;
      document.addEventListener('touchstart', function(event) {
          touchstartX = event.changedTouches[0].screenX;
          touchstartY = event.changedTouches[0].screenY;
          currentPage = rThis.getCurrentPage();
      }, false);

      document.addEventListener('touchend', function(event) {
          touchendX = event.changedTouches[0].screenX;
          touchendY = event.changedTouches[0].screenY;
          handleGesture();
      }, false); 

      function handleGesture() {
       /*  if (touchendX <= touchstartX) {
            console.log('Swiped left');
        } */
        
        /* if (touchendX >= touchstartX) {
            console.log('Swiped right');
        } */
        console.log(currentPage);
        if (touchendY < touchstartY) {
            console.log('Swiped up', touchendY-touchstartY);
            rThis.parallax.scrollTo(currentPage + 1);
        }
        
        else if (touchendY > touchstartY) {
            console.log('Swiped down', touchendY-touchstartY);
            rThis.parallax.scrollTo(currentPage - 1);
        }
    }
      
  }


  navClick(event) {
    var navIcon = document.getElementsByClassName("nav-icon");
    navIcon[0].classList.remove("closebtn");
    navIcon[0].innerHTML = "&#9776;";//&#9776
    if (document.getElementById("myNav")) document.getElementById("myNav").style.width = "0%";
    console.log(event.target.id)
    this.parallax.scrollTo(event.target.id);
  }

  render() {
    return (
      <div id="app1" style={{overflow: 'auto'}}>
        <div id="myNav" className="overlay">
          {/*  <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a>  */}
          <div className="overlay-content">
            <a className="nav-link" id="1" onClick={this.navClick.bind(this)}>{"Product & Services".toUpperCase()}</a>
            <a className="nav-link" id="2" onClick={this.navClick.bind(this)}>{"Data".toUpperCase()}</a>
            <a className="nav-link" id="3" onClick={this.navClick.bind(this)}>{"Goals".toUpperCase()}</a>
            <a className="nav-link" id="4" onClick={this.navClick.bind(this)}>{"Impact".toUpperCase()}</a>
            <a className="nav-link" id="5" onClick={this.navClick.bind(this)}>{"Community".toUpperCase()}</a>
            <a className="nav-link" id="6" onClick={this.navClick.bind(this)}>{"Craft".toUpperCase()}</a>
            <a className="nav-link" id="7" onClick={this.navClick.bind(this)}>{"Adaptability".toUpperCase()}</a>
          </div>
        </div>
        {/* <!-- Navigation--> */}
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
          <div className="container-fluid">
            <a className="navbar-brand js-scroll-trigger" onClick={() => this.parallax.scrollTo(0)}>KNKT</a>
            <span className="nav-icon" style={{ fontSize: "30px", cursor: "pointer" }} onClick={openNav}>&#9776;</span>
          </div>
        </nav>
        <div className="pull-center sticky-knkt">
          <h1 className="text-uppercase knkt-text">KNKT</h1>
        </div>
        <Parallax ref={ref => (this.parallax = ref)} pages={8}>
          <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#805E73' }} />
          <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#87BCDE' }} />

          <ParallaxLayer offset={0} speed={0} factor={8} className="backgroundSize-cover" style={{ background: "#0A0A0A url('../asset/img/Vector Smart Object.png')", backgroundSize: 'cover' }} />

          {/*  <ParallaxLayer offset={1.3} speed={-0.3} style={{ pointerEvents: 'none' }}>
          <img src={paSticker} style={{ width: '15%', marginLeft: '70%' }} />
        </ParallaxLayer> */}
          <ParallaxLayer offset={1.3} speed={-0.3} style={{ opacity: 0.84 }}>
            <img src={orangeDiamond} style={{ width: '25%', marginLeft: '50%' }} />
          </ParallaxLayer>

          {/* <ParallaxLayer offset={1.4} speed={-0.25} style={{ opacity: 0.84 }}>
            <img src={greenDiamond} style={{ width: '25%', marginLeft: '50%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={1.5} speed={-0.2} style={{ opacity: 0.84 }}>
            <img src={redDiamond} style={{ width: '25%', marginLeft: '50%' }} />
          </ParallaxLayer> */}

          <ParallaxLayer offset={3} speed={0.8} style={{ opacity: 0.8 }}>
            <img src={paSticker} style={{ display: 'block', width: '20%', marginLeft: '55%' }} />
            <img src={videoTape} style={{ display: 'block', width: '10%', marginLeft: '15%' }} />
          </ParallaxLayer>

          {/*   <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.4 }}>
          <img src={sdCard} style={{ display: 'block', width: '20%', marginLeft: '70%' }} />
          {/* <img src={sdCard} style={{ display: 'block', width: '20%', marginLeft: '40%' }} /> 
        </ParallaxLayer> */}

{/*           <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.6 }}>
            <img src={tape} style={{ display: 'block', width: '10%', marginLeft: '10%' }} />
            <img src={tape} style={{ display: 'block', width: '20%', marginLeft: '75%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.8 }}>
            <img src={vinyl} style={{ display: 'block', width: '20%', marginLeft: '60%' }} />
            <img src={vinyl} style={{ display: 'block', width: '25%', marginLeft: '30%' }} />
            <img src={vinyl} style={{ display: 'block', width: '10%', marginLeft: '80%' }} />
          </ParallaxLayer> */}

          <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.9 }}>
            <img src={audioCassette} style={{ display: 'block', width: '20%', marginLeft: '5%' }} />
            <img src={cartridge} style={{ display: 'block', width: '15%', marginLeft: '75%' }} />
          </ParallaxLayer>

          {/*  <ParallaxLayer offset={0.1} speed={0.8} style={{ opacity: 0.8 }}>
           <img src={purpleBlob} style={{height: '100%', marginLeft: '25%'}} />
        </ParallaxLayer> */}
          <ParallaxLayer offset={0.65} speed={-0.3} style={{ opacity: 0.84 }}>

            <p style={{ width: '100%', marginLeft: '25.5%' }} className="mb-0 text-white-50 main-section">
              Every <span className="knkt-text-detail">Fan.</span>&nbsp;
            Every <span className="knkt-text-detail">Performance.</span>&nbsp;
            Every <span className="knkt-text-detail">Data Point.</span>&nbsp;
            Everything <span className="knkt-text-detail">Music.</span>&nbsp;
          </p>
          </ParallaxLayer>


          {/*  <ParallaxLayer offset={0.4} speed={0.4} style={{ opacity: 1}}>
       <h1 className="text-uppercase knkt-text" style={{height: '100%', marginLeft: '25%', position: 'sticky'}}>KNKT</h1>
        </ParallaxLayer> */}
          {/*   <ParallaxLayer offset={1.1} speed={0.8} style={{ opacity: 0.8 }}>
           <img src={orangeTrapezium} style={{height: '100%', marginLeft: '25%'}} />
        </ParallaxLayer> */}
          <ParallaxLayer offset={2.1} speed={0.8} style={{ opacity: 0.8 }}>
            <img src={orangeTrapezium} style={{ height: '100%', marginLeft: '25%' }} className="orange-trapezium" />
          </ParallaxLayer>
          <ParallaxLayer offset={3.1} speed={0.8} style={{ opacity: 0.8 }}>
            <img src={purpleShape} style={{ height: '100%', marginLeft: '25%' }} className="orange-trapezium" />
          </ParallaxLayer>
          <ParallaxLayer offset={4.1} speed={0.8} style={{ opacity: 0.8 }}>
            <img src={purpleBlob} style={{ height: '100%', marginLeft: '25%' }} className="orange-trapezium" />
          </ParallaxLayer>

          {/* <ParallaxLayer offset={2.5} speed={-0.4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pointerEvents: 'none' }}>
            <img src={url('earth')} style={{ width: '60%' }} />
          </ParallaxLayer> */}

          {/* <ParallaxLayer
          offset={2}
          speed={-0.3}
          style={{
            backgroundSize: '80%',
            backgroundPosition: 'center'
          }}
        >
        </ParallaxLayer> */}

          <ParallaxLayer
            offset={0}
            speed={0.1}
            onClick={() => this.handleOnClick()}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/*  <div className="h-100 pull-center">
                        <div className="row w-100">
                        {/* <div className="col-lg-3 offset-2 orange-trapezium-containter text-left"> 
                        <img src={purpleBlob} style={{height: '100%'}}   className="orange-trapezium"/></div>
                            <div className="col-lg-6 text-right"> <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1></div>
                            <div className="col-lg-6">
                                <div className="text-center h-100 project">
                                    <div className="d-flex h-100">
                                        <div className="project-text w-100 my-auto text-center text-lg-left">
                                            <p className="mb-0 text-white-50 info-font">  Every Fan.<br></br>
                                                Every Performance.<br></br>
                                                Every Data Point.<br></br>
                                                Every Music.<br></br></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6"></div>
                            <div className="col-lg-6 no-padding"><img src={yellowBar}
                                srcSet="img/cta-button@2x.png 2x,
                                        img/cta-button@3x.png 3x"
                                className="cta-button"/></div>
                        </div>
                </div> */}
            <div></div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.handleOnClick()}>
            {/* <img src={url('clients-main')} style={{ width: '40%' }} /> */}

            <div className="row w-100">
              <div className="col-lg-6 text-right">{/* <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1> */}</div>
              <div className="col-lg-6 three-shapes-container pull-center">
                {/* 
                                    <img src={orangeDiamond} className="orange-trapezium"/>
                                    <img src={greenDiamond} className="orange-trapezium"/>
                                    <img src={redDiamond}className="orange-trapezium"/> */}
              </div>
            </div>
          </ParallaxLayer>

          <ParallaxLayer
            offset={2}
            speed={0.1}
            onClick={() => this.handleOnClick()}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* <div className="h-100 pull-center"> */}
            <div className="row w-100">
              {/*                         <div className="col-lg-3 offset-2 orange-trapezium-containter text-left"> <img src={orangeTrapezium}
                                    className="orange-trapezium"/></div> */}
              <div className="col-lg-6 text-right">{/* <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1> */}</div>
              <div className="col-lg-4">
                <div className="text-center h-100 project">
                  <div className="d-flex h-100">
                    <div className="project-text w-100 my-auto text-center text-lg-left">
                      {/*   <p className="mb-0 text-white-50 info-font">  <strong>WITH DATA</strong><br></br>
                                               <span className="text-detail"> 
                                                 KNKT provides social media,
                                                 streaming and ticket analytics
                                                 at an artist's fingertips. Algorithms
                                                 and experts turn data into personalized directives that will 
                                                 maximize fan engagement, audience
                                                 impact and proffesional
                                                 connections.
                                                </span>
                                                </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*    </div> */}
          </ParallaxLayer>
          <ParallaxLayer
            offset={3}
            speed={0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.handleOnClick()}>
            {/* <img src={url('clients-main')} style={{ width: '40%' }} /> */}
            <div className="row w-100">
              {/*  <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={purpleShape}
                                    className="orange-trapezium"/></div> */}
              <div className="col-lg-6 text-right">{/* <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1> */}</div>
              <div className="col-lg-4">
                {/* <div className="text-center h-100 project">
                                    <div className="d-flex h-100">
                                        <div className="project-text w-100 my-auto text-center text-lg-left">
                                            <p className="mb-0 text-white-50 info-font"><strong>WITH GOALS</strong><br></br>
                                               <span className="text-detail"> 
                                                 Let our algorithms and experts
                                                convert your data into actionable
                                                directives that will catalyze your
                                                proffesional and digital presence.
                                                </span>
                                                </p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={4}
            speed={0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.handleOnClick()}>
            <div className="row w-100">
              {/*  <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={orangeTrapezium}
                                    className="orange-trapezium"/></div> */}
              <div className="col-lg-6 text-right">{/* <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1> */}</div>
              <div className="col-lg-4">
                <div className="text-center h-100 project">
                  <div className="d-flex h-100">
                    <div className="project-text w-100 my-auto text-center text-lg-left">
                      {/*     <p className="mb-0 text-white-50 info-font"><strong>WITH IMPACT</strong><br></br>
                        <span className="text-detail">
                          Quantify your progress with our proprietary AI Score.
                          This number tracks your weekly momentum across fan engagement, event attendance, and streaming performance.
                          Like a credit score, but for your career.
                          </span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={5}
            speed={0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.handleOnClick()}>
            <div className="row w-100">
              {/*  <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={orangeTrapezium}
                                    className="orange-trapezium"/></div> */}
              <div className="col-lg-6 text-right">{/* <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1> */}</div>
              <div className="col-lg-4">
                <div className="text-center h-100 project">
                  <div className="d-flex h-100">
                    <div className="project-text w-100 my-auto text-center text-lg-left">
                      {/*   <p className="mb-0 text-white-50 info-font"><strong>WITH COMMUNITY</strong><br></br>
                        <span className="text-detail">
                          Access KNKT’S SEO-optimized marketing tools to increase ticket sales,
                          build campaigns, maximize reach, promote new releases and more.
                        </span>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={6}
            speed={0.1}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => this.handleOnClick()}>
            <div className="row w-100">
              {/*  <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={orangeTrapezium}
                                    className="orange-trapezium"/></div> */}
              <div className="col-lg-6 text-right">{/* <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1> */}</div>
              <div className="col-lg-4">
                {/* <div className="text-center h-100 project">
                  <div className="d-flex h-100">
                    <div className="project-text w-100 my-auto text-center text-lg-left">
                      <p className="mb-0 text-white-50 info-font"><strong>WITH CRAFT</strong><br></br>
                        <span className="text-detail">
                          We offer products and tools for you to efficiently manage your career,
                          so that you can focus on what you do best - inspire us with your creations.
                        </span>
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </ParallaxLayer>
          <div id="projects">
            <ParallaxLayer
              offset={7}
              speed={0.1}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => this.handleOnClick()}>
              <div className="row w-100">
                {/*  <div className="col-lg-3 offset-3 orange-trapezium-containter text-left"> <img src={orangeTrapezium}
                                      className="orange-trapezium"/></div> */}
                <div className="col-lg-6 text-right">{/* <h1 className="mx-auto my-0 text-uppercase knkt-text">KNKT</h1> */}</div>
                <div className="col-lg-4">
                  {/*  <div className="text-center h-100 project">
                                      <div className="d-flex h-100">
                                          <div className="project-text w-100 my-auto text-center text-lg-left">
                                          <p className="mb-0 text-white-50 info-font"><strong>WITH ADAPTABILITY</strong><br></br>
                                              <span className="text-detail"> 
                                              Our platform adapts to incorporate the newest applications and technology so you don’t have to worry about losing your work to the latest trend. 
                                              We’re an agnostic alternative that automatically keeps up with the times. 
                                              Adaptable and evergreen, KNKT was created to bring you peace of mind.
                                              </span>
                                          </p>
                                          </div>
                                      </div>
                                  </div> */}
                </div>
              </div>
            </ParallaxLayer>
            </div>

          <ParallaxLayer offset={0.72} speed={-0.1} style={{ opacity: 0.84 }}>
            <p style={{ width: '100%', marginLeft: '25.5%', fontSize: '22px' }} className="mb-0 text-white-50 main-section-2 info-font">
              All the tools and technology an artist needs to succeed, integrated into <span className="knkt-text-detail">one powerful platform.</span>
            </p>
          </ParallaxLayer>


          <ParallaxLayer offset={2.42} speed={-0.1} style={{ opacity: 1.0, zIndex: 20 }} onClick={() => this.handleOnClick()}>
            <p style={{ width: '35%', marginLeft: '50.5%' }} className="mb-0 text-white-50 info-font">  <strong>WITH DATA</strong><br></br>
              <span className="text-detail">
                KNKT provides social media,
                streaming and ticket analytics
                at an artist's fingertips. Algorithms
                and experts turn data into personalized directives that will
                maximize fan engagement, audience
                impact and proffesional
                connections.
          </span>
            </p>
          </ParallaxLayer>
          <ParallaxLayer offset={3.42} speed={-0.1} style={{ opacity: 1.0, zIndex: 20 }} onClick={() => this.handleOnClick()}>
            <p style={{ width: '35%', marginLeft: '50.5%' }} className="mb-0 text-white-50 info-font">  <strong>WITH GOALS</strong><br></br>
              <span className="text-detail">
                Let our algorithms and experts
                convert your data into actionable
                directives that will catalyze your
                proffesional and digital presence.
          </span>
            </p>
          </ParallaxLayer>
          <ParallaxLayer offset={4.42} speed={-0.1} style={{ opacity: 1.0, zIndex: 20 }} onClick={() => this.handleOnClick()}>
            <p style={{ width: '35%', marginLeft: '50.5%' }} className="mb-0 text-white-50 info-font">  <strong>WITH IMPACT</strong><br></br>
              <span className="text-detail">
                Quantify your progress with our proprietary AI Score.
                This number tracks your weekly momentum across fan engagement, event attendance, and streaming performance.
                Like a credit score, but for your career.
          </span>
            </p>
          </ParallaxLayer>
          <ParallaxLayer offset={5.42} speed={-0.1} style={{ opacity: 1.0, zIndex: 20 }} onClick={() => this.handleOnClick()}>
            <p style={{ width: '35%', marginLeft: '50.5%' }} className="mb-0 text-white-50 info-font">  <strong>WITH COMMUNITY</strong><br></br>
              <span className="text-detail">
                Access KNKT’S SEO-optimized marketing tools to increase ticket sales,
                build campaigns, maximize reach, promote new releases and more.
          </span>
            </p>
          </ParallaxLayer>
          <ParallaxLayer offset={6.42} speed={-0.1} style={{ opacity: 1.0, zIndex: 20 }} onClick={() => this.handleOnClick()}>
            <p style={{ width: '35%', marginLeft: '50.5%' }} className="mb-0 text-white-50 info-font">  <strong>WITH CRAFT</strong><br></br>
              <span className="text-detail">
                We offer products and tools for you to efficiently manage your career,
                so that you can focus on what you do best - inspire us with your creations.
          </span>
            </p>
          </ParallaxLayer>
          <ParallaxLayer offset={7.42} speed={-0.1} style={{ opacity: 1.0, zIndex: 20 }} onClick={() => this.handleOnClick()}>
            <p style={{ width: '35%', marginLeft: '50.5%' }} className="mb-0 text-white-50 info-font">  <strong>WITH ADAPTABILITY</strong><br></br>
              <span className="text-detail">
                Our platform adapts to incorporate the newest applications and technology so you don’t have to worry about losing your work to the latest trend.
                We’re an agnostic alternative that automatically keeps up with the times.
                Adaptable and evergreen, KNKT was created to bring you peace of mind.
          </span>
            </p>
          </ParallaxLayer>
        </Parallax>
      </div>
    )
  }
}

