import React from 'react';
import { Col } from 'react-bootstrap';

import Footer from './Footer';

const styles = {
  parent: {
    color: '#cccccc',
    backgroundColor: '#000000'
  },
  title: {
    marginTop: 20,
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  footer: {
    marginTop: 50
  }
};

const Privacy = () => (
  <Col xs={{ span: 10, offset: 1}} style={styles.parent}>
    <p style={styles.title}>
      <h1>Privacy Policy</h1>
    </p>

    <p style={styles.center}>This Agreement was last modified on January 5, 2024.</p>

    <p>Protecting your privacy is really important to us. With this in mind, we’re providing this Privacy Policy to
      explain our practices regarding the collection, use and disclosure of information that we receive through our
      Services. This Privacy Policy does not apply to any third-party websites, services or applications, even if they
      are accessible through our Services. Also, please note that, unless we define a term in this Privacy Policy, all
      capitalized terms used in this Privacy Policy have the same meanings as in our Terms of Service. So, please make
      sure that you have read and understand our Terms of Service.</p>

    <p>
      <strong>Revisions to this Privacy Policy</strong>
      <br />
      Any information that is collected via our Services is covered by the Privacy Policy in effect at the time such
      information is collected. We may revise this Privacy Policy from time to time. If we make any material changes
      to this Privacy Policy, we’ll notify you of those changes by posting them on the Services or by sending you an
      email or other notification, and we’ll update the “Last Updated Date” above to indicate when those changes will
      become effective.
    </p>

    <p>
      <strong>Collection and Use of Information</strong>
      <br />
      <strong>Information Collected or Received from You</strong>
      <br />
      We collect information you provide directly to us. For example, we collect information when you participate in
      any interactive features of our Services, fill out a form, request customer support, provide any contact or
      identifying information or otherwise communicate with us. The types of information we may collect include your
      name, email address, postal address, credit card information and other contact or identifying information you
      choose to provide.
    </p>

    <p>
      <strong>Information We Collect Automatically When You Use the Services</strong>
      <br />
      <strong>Account Holder Information</strong>
      <br />
      If you create an Account, we’ll extract from your Facebook Account, YouTube Account, Twitter Account, Instagram
      Account, SoundCloud Account or Spotify Account (as applicable) certain personal information that can be used to
      identify you, such as your name and email address (“Personally Identifiable Information” or “PII”), that the
      privacy settings that you have established on Facebook, SoundCloud or Spotify permit us to access. In addition,
      if after you create an Account, you connect it with one of your Third Party Accounts, we’ll be able to access
      and collect certain information, including your PII, that your privacy settings on such Third Party Account
      permit us to access. We may also collect your gender, zip code and other information that is not considered PII
      because it cannot be used by itself to identify you. When you make Donations, we may collect your credit card
      information if you opt to pay using a credit card. In addition, if you participate in an Artist’s Promotion, we
      may collect Promotion Data from or about you, which may include PII, like your name and email address, but also
      your birthday, location, recent Tweets, and Spotify playlists.
    </p>

    <p>
      <strong>Visitor Information</strong>
      <br />
      If you don’t register for an Account, but join our waitlist for future features or subscribe to our mailing list
      for receiving updates from our blog, we’ll collect your PII, such as your name and email address. We may use
      your PII to contact you by email any time we release a new feature or update our blog.
      <br />
      <br />
      Like many website owners and operators, we use automated data collection tools such as Cookies and Web Beacons
      to collect certain information.
      <br />
      <br />
      "Cookies" are small text files that are placed on your hard drive by a Web server when you access our Services.
      We may use both session Cookies and persistent Cookies to identify that you’ve logged in to the Services and to
      tell us how and when you interact with our Services. We may also use Cookies to monitor aggregate usage and web
      traffic routing on our Services and to customize and improve our Services. Unlike persistent Cookies, session
      Cookies are deleted when you log off from the Services and close your browser. Although most browsers
      automatically accept Cookies, you can change your browser options to stop automatically accepting Cookies or to
      prompt you before accepting Cookies. Please note, however, that if you don’t accept Cookies, you may not be able
      to access all portions or features of the Services. Some third-party services providers that we engage may also
      place their own Cookies on your hard drive. Note that this Privacy Policy covers only our use of Cookies and
      does not include use of Cookies by such third parties.
      <br />
      <br />
      "Web Beacons" (also known as web bugs, pixel tags or clear GIFs) are tiny graphics with a unique identifier that
      may be included on our Services for several purposes, including to deliver or communicate with Cookies, to track
      and measure the performance of our Services and to monitor how many visitors view our Services. Unlike Cookies,
      which are stored on the user’s hard drive, Web Beacons are typically embedded invisibly on web pages.
    </p>

    <p>
      <strong>Information Related to Use of the Services</strong>
      <br />
      Our servers automatically record certain information about how a person uses our Services (we refer to this
      information as “Log Data”), including both Account holders and non-Account holders (either, a “User”). Log Data
      may include information such as a User’s Internet Protocol (IP) address, browser type, operating system, the
      pages or features of our Services to which a User browsed and the time spent on those pages or features, search
      terms, the links on our Services that a User clicked on, posts that a User liked, music that a User listened to
      and other statistics. We use Log Data to administer the Services and we analyze (and may engage third parties to
      analyze) Log Data to improve, customize and enhance our Services by expanding their features and functionality
      and tailoring them to our Users’ needs and preferences.
    </p>

    <p>
      <strong>Location Information</strong>
      <br />
      When you create your Account using your Facebook Account, Twitter Account, YouTube Account, SoundCloud Account,
      or Spotify Account (as applicable) and when you connect your Account with your other Third Party Accounts, we
      may collect and store information about your location if your privacy settings on such Third Party Account and
      your Facebook Account, SoundCloud, Spotify, Twitter, and Google Account(s) permits us to access such
      information. We may use location information to improve and personalize our Services for you. By accessing and
      using our services, you consent to the processing and transfer of your information in and to the United States
      and other countries.
    </p>

    <p>
      KNKT uses YouTube's API services, which are subject to the Google Privacy Policy.
    </p>

    <p>
      By accessing and using our services, you consent to the processing and transfer of your information in and to
      the United States and other countries.
    </p>

    <p>
      <strong>Information that We Share with Third Parties</strong>
      <br />
      We will not share any PII that we have collected from or regarding you except as described below:
    </p>

    <p>
      <strong>Information Shared with the General Public or other Account Holders</strong>
      <br />
      When you create your Page using the Services, the general public will be able to view your username and photo
      uploaded by you on your Page and other Account holders may be able to access any PII or non-PII made available
      by you on your Page via the Services, such as your name and photo.
    </p>

    <p>
      <strong>Information Shared with Artists</strong>
      <br />
      If we have your email address, we will share your email address with those Artists whose page you have
      interacted with or whose Artist Content you follow or to which you subscribe via the Services, and the Artists
      may use your email address to inform you about Promotions or to generally connect with you independent of the
      Services.
    </p>

    <p>
      In addition, if you participate in an Artist’s Promotion we will share Promotion Data, including PII, with the
      Artist running the Promotion. This information is shared with the Artist so they can administer your
      participation in the Promotion and learn about their fans. Artists may also use the information to message you
      inside and outside of the Services. [We will only share Promotion Data with an Artist when you expressly
      authorize us to do so.] Artists are contractually obligated to comply with applicable law in their use of the
      Promotion Data they may receive from us about you.
    </p>

    <p>
      <strong>Information Shared with Our Services Providers</strong>
      <br />
      We may engage third party services providers to work with us to administer and provide the Services. These third
      party services providers have access to your PII only for the purpose of performing services on our behalf. For
      example we will share your Payment Information with Stripe or other payment services providers.
    </p>

    <p>
      <strong>Information Shared with Third Parties</strong>
      <br />
      We may share with third parties, including advertisers and brands, anonymized, aggregated data we collect about
      Users, such as de-identified demographic information, de-identified location information, information about the
      computer or device from which you access the Services, and information about the Artists that Users in
      non-personally identifiable demographic groups follow.
    </p>

    <p>
      <strong>Information Disclosed in Connection with Business Transactions</strong>
      <br />
      Information that we collect from our Users, including PII, is considered to be a business asset. Thus, if we are
      acquired by a third party as a result of a transaction such as a merger, acquisition or asset sale or if our
      assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our
      assets, including your PII, may be disclosed or transferred to a third party acquirer in connection with the
      transaction.
    </p>

    <p>
      <strong>Information Disclosed for Our Protection and the Protection of Others</strong>
      <br />
      We cooperate with government and law enforcement officials or private parties to enforce and comply with the
      law. We may disclose any information about you to government or law enforcement officials or private parties as
      we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including
      subpoenas); (ii) to protect our property, rights and safety and the property, rights and safety of a third party
      or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally
      actionable activity.
    </p>

    <p>
      <strong>Your Choices</strong>
      <br />
      We offer you choices regarding the collection, use and sharing of your PII and we’ll respect the choices you
      make. Please note that if you decide not to provide us with the PII that we request, you may not be able to
      access all of the features of the Services.
    </p>

    <p>
      <strong>Opt-Out</strong>
      <br />
      We may periodically send you free e-mails that directly promote our Services. When you receive such promotional
      communications from us you will have the opportunity to “opt-out” by following the unsubscribe instructions
      provided in the e-mail you receive. We do need to send you certain communications regarding the Services and you
      will not be able to opt out of those communications – e.g., communications regarding updates to our Terms of
      Service or this Privacy Policy or information about billing.
    </p>

    <p>
      <strong>Modifying Your Information</strong>
      <br />
      You can access and modify the PII associated with your profile on your Account by editing it through web forms
      available on the Services. If you want us to delete data related to your Account, please contact us at
      support@knkt.io with your request. We’ll take steps to delete your information as soon we can, but some
      information may remain in archived/backup copies for our records or as otherwise required by law.
    </p>

    <p>
      <strong>Responding to Do Not Track Signals</strong>
      <br />
      Our Site does not have the capability to respond to “Do Not Track” signals received from various web browsers.
      You may opt out for the collection of your social metrics using the features available on the Services.
    </p>

    <p>
      <strong>The Security of Your Information</strong>
      <br />
      We take reasonable administrative and electronic measures designed to protect the information that we collect
      from or about you (including your PII) from unauthorized access, use or disclosure. When you enter sensitive
      information on our forms, we encrypt this data using SSL or other technologies. Please be aware, however, that
      no method of transmitting information over the Internet or storing information is completely secure.
      Accordingly, we cannot guarantee the absolute security of any information.
    </p>

    <p>
      <strong>Links to Other Sites</strong>
      <br />
      Our Services may contain links to websites and services that are owned or operated by third parties (each, a
      “Third-party Service”). Any information that you provide on or to a Third-party Service or that is collected by
      a Third-party Service is provided directly to the owner or operator of the Third-party Service and is subject to
      the owner’s or operator’s privacy policy. We’re not responsible for the content, privacy or security practices
      and policies of any Third-party Service. To protect your information we recommend that you carefully review the
      privacy policies of all Third-party Services that you access.
    </p>

    <p>
      <strong>International Transfer</strong>
      <br />
      Your PII may be transferred to, and maintained on, computers located outside of your state, province, country or
      other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction.
      If you’re located outside the United States and choose to provide your PII to us, we may transfer your PII to
      the United States and process it there.
    </p>

    <p>
      <strong>Our Policy Toward Children</strong>
      <br />
      Our Services are not directed to children under 13 and we do not knowingly collect PII from children under 13.
      If we learn that we have collected PII of a child under 13 we will take steps to delete such information from
      our files as soon as possible.
    </p>

    <p>
      <strong>Your California Privacy Rights</strong>
      <br />
      California residents may request and obtain from us, once a year, free of charge, a list of third parties, if
      any, to which we disclosed their PII for direct marketing purposes during the preceding calendar year and the
      categories of PII shared with those third parties. If you are a California resident and wish to obtain that
      information, please submit your request by sending us an email at support@knkt.io with “California Privacy
      Rights” in the subject line.
      <br />
      Questions?
    </p>

    <p>
      Please contact us at support@knkt.io if you have any questions about our Privacy Policy.
    </p>

    <div style={styles.footer}>
      <Footer />
    </div>
  </Col>
);


export default Privacy;