import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import './fontawesome/css/all.css';
//import "https://use.typekit.net/ern5ahy.css";


import Homepage from './Components/HomePage';
import ParallaxEx from './Components/Parallax';
import Fullpage from './Components/FullPage';
import Privacy from './Components/Privacy';
import Terms from './Components/Terms';

function App() {
  return (
    <Router>
    <div className="App">
      <div className="App-header">
     {/*  <Homepage/> */}
     {/*  <ParallaxEx/> */}
     <Routes>
    <Route path='/' exact element={<Fullpage/>}></Route> 
    <Route path='/privacy' element={<Privacy/>}></Route>
    <Route path='/terms' element={<Terms/>}></Route>
    </Routes>
      </div>
    </div>
    
    </Router>
  );
}

export default App;
