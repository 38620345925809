/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef, forwardRef, useContext, Fragment } from "react";
import ReactDOM from "react-dom";
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage, { f } from "@fullpage/react-fullpage";
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { Form, Button, Col, InputGroup, Modal, Row } from 'react-bootstrap';
import orangeDiamond from '../Images/Rectangle Copy.png';
import orangeTrapezium from '../Images/rectangle-copy.png';
import purpleShape from '../Images/Rectangle.png';
import purpleBlob from '../Images/page1_bg.png';
import audioCassette from '../assets/img/Audio Cassette.png';
import cartridge from '../assets/img/Cartridge.png';
import baug1 from '../Images/baug_1.png';
import baug2 from '../Images/baug_2.png';
import baug3 from '../Images/baug_3.png';
import baug4 from '../Images/baug_4.png';
import Footer from "./Footer";

import axios from 'axios';
import { callApi } from './util';
//import "./styles.css";


class MySection extends React.Component {

  render() {
    let classN = "section section" + this.props.id;
    return (
      <div className={classN}>
        {this.props.content}
        {this.props.img}
      </div>
    );
  }
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ backgroundColor: '#000000' }}
    >

      <Modal.Body>
        <iframe src="https://cdn.forms-content.sg-form.com/3b9eafef-4979-11eb-8872-06e65b5cc837" width="100%" height="750px" style={{ border: 0 }} loading="lazy" />
        <Button onClick={props.onHide} style={{ position: 'absolute', backgroundColor: '#093BD2', bottom: '19%', left: '50%', width: '138px', letterSpacing: 'normal' }}>Close</Button>
      </Modal.Body>
    </Modal>
  );
}

const anchors = ["firstPage", "secondPage", "thirdPage"];
const conatctFormURL = 'https://i.prefinery.com/projects/zuavt1gl/users/post';


const FullpageWrapper = () => {

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [validated, setValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [expandText, setExpandText] = useState(false);
  const [sectionIndex, setSectionIndex] = useState("s0");

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      submitForm()
    }
    console.log(form.checkValidity());
    setValidated(true);
  }

  function collapse() {
    let acc = document.querySelectorAll(".accordion");
    let i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        let panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }

  }

  //decode effect start
  /* ------------------------------------------------------------------------ *  
  4 states per letter: Transparent | Line | Block | Visible.
  These states are shuffled for a unique "decode" effect each time.
  * ------------------------------------------------------------------------ */
  const inviteButton = (btnText) => {
    return (
      <Button variant="neon" className="neon" onClick={() => setModalShow(true)}>
        <span></span>
        <span></span>
        <span></span>
        {btnText}
      </Button>
    );
  }

  function decodeText() {
    var text = document.getElementsByClassName('decode-text')[0];
    if (text) {
      // debug with
      // console.log(text, text.children.length);

      // assign the placeholder array its places
      var state = [];
      for (var i = 0, j = text.children.length; i < j; i++) {
        text.children[i].classList.remove('state-1', 'state-2', 'state-3');
        state[i] = i;
      }

      // shuffle the array to get new sequences each time
      var shuffled = shuffle(state);

      for (var i = 0, j = shuffled.length; i < j; i++) {
        var child = text.children[shuffled[i]];
        let classes = child.classList;

        // fire the first one at random times
        var state1Time = Math.round(Math.random() * (2000 - 300)) + 50;
        if (classes.contains('text-animation')) {
          setTimeout(firstStages.bind(null, child), state1Time);
        }
      }
    }
  }

  // send the node for later .state changes
  function firstStages(child) {
    if (child.classList.contains('state-2')) {
      child.classList.add('state-3');
    } else if (child.classList.contains('state-1')) {
      child.classList.add('state-2')
    } else if (!child.classList.contains('state-1')) {
      child.classList.add('state-1');
      setTimeout(secondStages.bind(null, child), 100);
    }
  }
  function secondStages(child) {
    if (child.classList.contains('state-1')) {
      child.classList.add('state-2')
      setTimeout(thirdStages.bind(null, child), 100);
    }
    else if (!child.classList.contains('state-1')) {
      child.classList.add('state-1')
    }
  }
  function thirdStages(child) {
    if (child.classList.contains('state-2')) {
      child.classList.add('state-3')
    }
  }

  function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  useEffect(() => {
    // Demo only stuff
    decodeText();
    collapse();
    // beware: refresh button can overlap this timer and cause state mixups
    setInterval(function () { decodeText(); }, 5000);
  }, [])
  //decode effect end

  const submitForm = () => {
    console.log(firstName, lastName, email);
    let data = {
      user: {
        profile: {
          email: email,
          first_name: firstName,
          last_name: lastName
        }
      },
      referrer: document.referrer, // HTTP Referrer
      referral_token: 'fAke', // Credit owner of 'fAke' with referring this user
      utm_source: 'Google',
      utm_medium: 'cpc',
      utm_campaign: 'charity_benefit',
      utm_term: 'bruce+wayne+charity+benefit',
      utm_content: 'banner'
    }


    callApi(conatctFormURL, 'post', data).then((data) => {
      console.log(data);
    });
  }

  return (
    <Fragment>
    <div className={"pull-center sticky-knkt "+sectionIndex}>
        <h1 className="text-uppercase knkt-text">KNKT</h1>
    </div>
    <ReactFullpage
      //anchors={anchors}
      parallax={true}
      /* navigation */
      css3={true}
      easingcss3={"ease-in-out"}
      scrollingSpeed={900}
      //navigationTooltips={anchors}
      sectionsColor={["#1B1B1B", "#000000", "#1B1B1B", "#1B1B1B", "#1B1B1B", "#1B1B1B", "#1B1B1B", "#1B1B1B", "#1B1B1B"]}
      onLeave={(origin, destination, direction) => {
        console.log("onLeave event", { origin, destination, direction });
      }}
      afterLoad={(origin, destination, direction) => {
        console.log("afterLoad event", { origin, destination, direction }, destination.index);
        setSectionIndex("s"+destination.index);
      }}
      render={({ state, fullpageApi }) => {
        console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

        return (
          <div>
            <MySection id="1" content={
              <div>
                <Row><Col lg="12"></Col></Row>
                <div style={{ width: '100%', marginLeft: '25.5%', paddingTop: "5rem" }} className="mb-0 text-white-50 main-section">

                  {/*               Every <span className="knkt-text-detail">Fan.</span>&nbsp;
                  Every <span className="knkt-text-detail">Performance.</span>&nbsp;
                  Every <span className="knkt-text-detail">Data Point.</span>&nbsp;
                  Everything <span className="knkt-text-detail">Music.</span>&nbsp; */}
                  {/* (pronounced ‘Connect’) /kəˈnekt/  */}
                  <Row className="decode-wrap"><div className="decode-text text-left">
                    <div className="text-animation">(</div>
                    <div className="text-animation">p</div>
                    <div className="text-animation">r</div>
                    <div className="text-animation">o</div>
                    <div className="text-animation">n</div>
                    <div className="text-animation">o</div>
                    <div className="text-animation">u</div>
                    <div className="text-animation">n</div>
                    <div className="text-animation">c</div>
                    <div className="text-animation">e</div>
                    <div className="text-animation">d</div>
                    <div className="space"></div>

                    <div className="text-animation">‘</div>
                    <div className="text-animation">C</div>
                    <div className="text-animation">o</div>
                    <div className="text-animation">n</div>
                    <div className="text-animation">n</div>
                    <div className="text-animation">e</div>
                    <div className="text-animation">c</div>
                    <div className="text-animation">t</div>
                    <div className="text-animation">’</div>
                    <div className="text-animation">)</div>
                    <div className="space"></div>

                    <div className="text-animation">/</div>
                    <div className="text-animation">k</div>
                    <div className="text-animation">ə</div>
                    <div className="text-animation">ˈ</div>
                    <div className="text-animation">n</div>
                    <div className="text-animation">e</div>
                    <div className="text-animation">k</div>
                    <div className="text-animation">t</div>
                    <div className="text-animation">/</div>
                  </div></Row>
                  <Row><Col style={{height: '6vw'}} lg="12"></Col></Row>
                  <Row>
                    <div className="content">
                      <div className="content__container">
                        <p className="content__container__text">
                          Connecting
                        </p>
                        <span></span>
                        <ul className="content__container__list">
                          <li className="content__container__list__item">DJs</li>
                          <li className="content__container__list__item">Producers</li>
                          <li className="content__container__list__item">Fans</li>
                          <li className="content__container__list__item">Artists</li>
                          <li className="content__container__list__item">Labels</li>
                          <li className="content__container__list__item">Venues</li>
                          <li className="content__container__list__item">Managers</li>
                          <li className="content__container__list__item">Rappers</li>
                          <li className="content__container__list__item">Performers</li>
                          <li className="content__container__list__item">Vocalists</li>
                          <li className="content__container__list__item">Agents</li>
                          <li className="content__container__list__item">PR</li>
                          <li className="content__container__list__item">Playlists</li>
                          <li className="content__container__list__item">Distributors</li>
                          <li className="content__container__list__item">Publishers</li>
                        </ul>
                      </div>
                    </div>
                  </Row>
                  <Row><span className="content_next_line" style={{ padding: '0 10px' }}>{"Through Community, Tools & Data"}</span></Row>

                  <Row>
                    <span></span>{inviteButton("REQUEST INVITE")}

                    <MyVerticallyCenteredModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </Row>
                </div>
                <span class="scroll-down-link scroll-down-arrow"></span>
              </div>
            } />
            <MySection id="2" content={<div className="row">
              <div className="col-lg-6 sm-height"> </div>
              <div className="col-lg-6 text-white sm-detail" style={{ fontSize: '1.5vw', paddingRight: '10%' }}>
                <strong>WITH DATA</strong><br></br>
                <span className="text-detail">
                  Integrate all your siloed channels into one consolidated platform
                  <span className="accordion"></span>
                  <div className="accordion-content">
                    Our algorithms and experts turn this data into personalized insights that will maximize your fan engagement,
                  audience impact and professional connections.</div>
                </span>
              </div>
              <span class="scroll-down-link scroll-down-arrow"></span>
            </div>}
            /* img={<img src={baug4} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
            />
            <MySection id="3" content={
              <div className="row">
                <div className="col-lg-6 sm-height"> </div>
                <div style={{ fontSize: '1.5vw', paddingRight: '10%' }} className="col-lg-6 text-white sm-detail">  <strong>WITH FANS</strong><br></br>
                  <span className="text-detail">
                    Build your community {"&"} superfan growth funnel with our marketing tools
                    <span className="accordion"></span>
                    <div className="accordion-content">
                      Build pre-launch anticipation for latest releases with Spotify playlist placements,
                    enable promolinks to your music, and more with KNKT marketing tools.</div>
                  </span>
                </div>
                <span class="scroll-down-link scroll-down-arrow"></span>
              </div>}
            /* img={<img src={baug3} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
            />
            <MySection id="4" content={
              <div className="row">
                <div className="col-lg-6 sm-height"> </div>
                <div style={{ fontSize: '1.5vw', paddingRight: '10%' }} className="col-lg-6 text-white sm-detail">  <strong>WITH REVENUE</strong><br></br>
                  <span className="text-detail">
                    Become a KNKT Pro and turn your knowledge into revenue
                    <span className="accordion"></span>
                    <div className="accordion-content">
                      Conduct one-on-ones with eager learners, accumulate subscribers and enjoy the benefits of immediate payouts.
                    Think you could be a KNKT Pro? Apply now. (hyperlinked)</div>
                  </span>
                </div>
                <span class="scroll-down-link scroll-down-arrow"></span>
              </div>}
            /* img={<img src={baug4} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
            />
            <MySection id="5" content={
              <div className="row">

                <div className="col-lg-6 sm-height"> </div>
                <div className="col-lg-6 text-white sm-detail" style={{ fontSize: '1.5vw', paddingRight: '15%' }}>   <strong>WITH CONVERSATIONS</strong><br></br>

                  <span className="text-detail">
                    Engage in dynamic conversations with vetted industry professionals
                      <span className="accordion"></span>
                    <div className="accordion-content">
                      With a click, chat with a KNKT Pro to brainstorm your new EP, seek contract advice, and inquire about anything music.
                      From launch strategy and distribution to SYNC licensing and royalty collection,
                    you’ll receive targeted advice from real experts.</div>
                  </span>
                </div>
                <span class="scroll-down-link scroll-down-arrow"></span>
              </div>}
            /* img={<img src={baug1} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
            />
            <MySection id="6" content={
              <div className="row">
                <div className="col-lg-6 sm-height"> </div>
                <div style={{ fontSize: '1.5vw', paddingRight: '15%' }} className="col-lg-6 text-white sm-detail text-white sm-detail">  <strong>WITH COMMUNITY</strong><br></br>
                  <span className="text-detail">
                    Start or join sub-communities to chat about the things you love, from techno to jazz, marketing to publishing {"&"} much more
                      <span className="accordion"></span>
                    <div className="accordion-content">
                      Take part in our moderator-led community chatrooms where our KNKT Pros host video and audio panels on trending music topics. Learn the latest industry trends, collaborate and make real-world connections all in one place.</div>
                  </span>
                </div>
                <span class="scroll-down-link scroll-down-arrow"></span>
              </div>}
            /* img={<img src={baug2} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
            />
            <MySection id="7" content={
              <div className="row">
                <div className="col-lg-6 sm-height"> </div>
                <div style={{ fontSize: '1.5vw', paddingRight: '10%' }} className="col-lg-6 text-white sm-detail">  <strong>WITH IMPACT</strong><br></br>
                  <span className="text-detail">
                    Quantify your career health with our proprietary AI Score
                    <span className="accordion"></span>
                    <div className="accordion-content">
                      We’ll track your weekly momentum across fan engagement,
                      event attendance, and streaming performance and compute it
                    into a comprehensive number that’s like a credit score for your career.</div>
                  </span>
                </div>
                <span class=" scroll-down-link scroll-down-arrow"></span>
              </div>}
            /* img={<img src={baug1} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
            />
            <MySection id="8" content={
              <div className="row">
                <div className="col-lg-6 sm-height"> </div>
                <div style={{ fontSize: '1.5vw' }} className="col-lg-6 text-white with-us"><span className="text-detail"><span></span>{inviteButton("  WITH US  ")}</span>
                </div>
                <span class="scroll-down-link scroll-down-arrow"></span>
              </div>}
            /* img={<img src={baug1} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
            />
          <MySection id="9" content={
              <div className="row">
                <div className="col-lg-6 sm-height"> </div>
                <div style={{ fontSize: '1.5vw' }} className="col-lg-6 text-white with-us">
                  <Footer/>
                </div>
                <span class="scroll-up-override scroll-up-override scroll-down-link scroll-down-arrow"></span>
              </div>}
            /* img={<img src={baug1} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />} */
          />
          
            {/*<MySection content={<p style={{ width: '35%', marginLeft: '50.5%', bottom: "37%", zIndex: "999", position: 'absolute' }} className="mb-0 text-white-50 info-font">  <strong>WITH YOUR CRAFT</strong><br></br>
              <span className="text-detail">
                Want more time to create?
                We offer products and tools for you to efficiently manage your career,
                so that you can focus on what you do best - inspire us with your creations.
                      </span>
            </p>}
              img={<img src={baug1} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />}
            /> */}
            {/* <MySection content={<p style={{ width: '35%', marginLeft: '50.5%', bottom: "38%", zIndex: "999", position: 'absolute' }} className="mb-0 text-white-50 info-font">  <strong>WITH GOALS</strong><br></br>
              <span className="text-detail">
                Struggling to stay on track?
                We’ve crafted a personalized,
                goal-achievement system that offers step-by-step guidance,
                so you can power up when there is an opportunity,
                and reinforce your strengths.
          </span></p>}
              img={<img src={baug2} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />}

            /> */}

            {/* <MySection content={<p style={{ width: '35%', marginLeft: '50.5%', bottom: "27%", zIndex: "999", position: 'absolute' }} className="mb-0 text-white-50 info-font">  <strong>WITH LONGEVITY</strong><br></br>
              <span className="text-detail">
                Worried about losing your Fans? We’re an agnostic alternative that automatically keeps up with the times,
                incorporating the newest applications and technologies. Adapt able and evergreen,
                KNKT was created to bring you peace of mind.
          </span>
            </p>}
              img={<img src={baug3} style={{ height: '100%', marginLeft: '25%', position: "absolute", bottom: 0 }} className="orange-trapezium" />}
            /> */}
            {/* <div id="contact-form" data-prefinery-prefill-referrer="http://localhost:3000/">
                               <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="First name"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Last name"
                        required
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>
                  <Button type="submit" variant="outline-primary">Submit</Button>
                </Form> 
                <iframe src="https://cdn.forms-content.sg-form.com/3b9eafef-4979-11eb-8872-06e65b5cc837" width="300px" height="600px"/>
                 <button className="prefinery-form-cta btn btn-primary">Sign Up Now!</button>
              </div>*/}
          </div>
        );
      }}
    />
    </Fragment>
  );
};

export default FullpageWrapper;
